import { User } from "../../screen/UserInterface/domain/user";
import { Ticket } from '../../screen/Suporte/domain/ticket';
import axios from 'axios';
import {Campaign} from "../../screen/Campaign/domain/campaign";


const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

const apiGooddata = axios.create({
  baseURL: 'https://analytics.totvs.com.br',
  timeout: 10000,
  withCredentials: true,
});

function getCampaign() {
  return api.get("/campaign", {
  });
}
function getCampaignNames() {
  return api.get("/campaign/names", {});
}

function getResumoEnvios(initialDate: string, finalDate: string) {
  return api.get(`campaign/date?initialDate=${initialDate}&finalDate=${finalDate}`,{
  });
}

function getCampaignBlackList(date : string) {
  return api.get(`/campaign/blacklist?date=${date}`, {
  });
}

function getcampaignprogress() {
  return api.get("/campaign/progress", {
  });
}

function getcampaignfulfilled() {
  return api.get("/campaign/fulfilled", {
  });
}

function createCampaign(campaign: FormData) {
  return api.post("/campaign", campaign, {
  });
}

function createCampaignCSV(campaign: Campaign) {
  return api.post("/campaign/csv", campaign, {
  });
}

function getPresignedUrl(fileName: string) {
  return api.get(`/campaign/presignedUrl?fileName=${fileName}`, {
  });
}

function createUser(user: User) {
  return api.post("/user", user, {
  });
}

function getUser() {
  return api.get("/user", {
  });
}

function deleteUser(id: string) {
  return api.delete(`/user/${id}`, {
  });
}

function updateUser(user: User, id: string) {
  return api.patch(`/user/${id}`, user,{
  });
}

function resetUserPassword(user: User) {
  return api.post("/user/resetPassword", user, {
  });
}

function getDelivery() {
  return api.get("/dynamo/status", {
  });
}

function getOverview() {
  return api.get("/dynamo/sentAndFailed", {
  });
}

function getSmsReceivedCsvExport(myDate: any) {
  return api.post(`dynamo/inbound-csv-export`, myDate,{
  });
}

function getSmsReceivedRange(myDate: any) {
  return api.post(`dynamo/smsReceivedRangeAll`, myDate,{
  });
}

function csvResponseReport(myDate: any) {
  return api.post(`dynamo/csvResponseReport`, myDate,{
  });
}

function getWebhookStatusCsvExport(myDate: any) {
  return api.post(`dynamo/csv-export`, myDate,{
  });
}

function getDetailedReport(filters: any) {
  return api.post(`dynamo/detailed-report`, filters,{
  });
}

function csvDetailedReport(filters: any) {
  return api.post(`dynamo/csvDetailed-report`, filters,{
  });
}

function getConsolidatedReport(filters: any) {
  return api.post(`dynamo/consolidated-report`, filters,{
  });
}

function sendEmail(ticket: Ticket) {
  return api.post(`suporte/createEmail`, ticket,{
  });
}

function loginSSO(myData: any) {
  return apiGooddata.post(`/gdc/account/customerlogin`, myData,{
  });
}

function sendEmailLink(email: string) {
  return api.post(`suporte/sendEmailLink`, { email: email });
}

function login(email: string, password: string) {
  const data = {
    email,
    password,
  };
  return api.post("/auth/login", data);
}

export const brokerApi = {
  getUser,
  updateUser,
  deleteUser,
  sendEmail,
  getcampaignprogress,
  getWebhookStatusCsvExport,
  createCampaignCSV,
  getCampaign,
  getCampaignBlackList,
  login,
  createUser,
  getDelivery,
  getSmsReceivedCsvExport,
  sendEmailLink,
  getResumoEnvios,
  getSmsReceivedRange,
  csvDetailedReport,
  getPresignedUrl,
  csvResponseReport,
  resetUserPassword,
  getCampaignNames,
  getDetailedReport,
  loginSSO,
  getConsolidatedReport,
};
